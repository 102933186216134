.thanksTitle {
  font-family: 'Rubik', sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 40.6778px;
  line-height: 48px;
  color: #111A22; }

.nextTitle {
  font-family: 'Rubik', sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 30.4065px;
  line-height: 36px;
  /* identical to box height */
  color: #353535; }

.nextStepsTitle {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  color: #353535;
  ul {
    list-style-type: disc;
    list-style-position: outside;
    li {} } }

.rewardsThanksTitle {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  color: #353535; }

.thanksExclusiveCard {
  background: #F8FAFB;
  border: 4.86365px solid #F4F6F7;
  box-shadow: 0px 12.6474px 15.8092px rgba(41, 41, 41, 0.25);
  border-radius: 10.9033px;
  .card-content {
    padding: 0.75rem 0.5rem !important;
    .imageCircle {
      border-radius: 60px; } } }
.exclusiveText {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 21.8066px;
  line-height: 100%;
  text-transform: uppercase;
  color: #515558;
  margin-bottom: 0 !important;
  mix-blend-mode: normal; }
.rewardsThanksCardButton {
  background: #000000;
  border: 1.6px solid #414141;
  box-shadow: 0px 3.30124px 3.30124px rgba(0, 0, 0, 0.25);
  border-radius: 4.95186px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 229%;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #FFFFFF;
  mix-blend-mode: normal;
  cursor: initial;
  &:hover {
    color: #FFFFFF;
    outline: none;
    border: 1.6px solid #414141; }
  &:focus {
    color: #FFFFFF; } }

.forYouText {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 108%;
  text-transform: uppercase;
  color: #515558;
  mix-blend-mode: normal; }

.forYouNFamText {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 108%;
  text-transform: uppercase;
  color: #515558;
  mix-blend-mode: normal; }

.shareRewardText {
  font-family: 'Rubik', sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #8A7C7C; }

@media screen and (min-width: 769px) and (max-width: 1248px) {
  .thanksTitle {
    font-size: 30px;
    line-height: 38px; }
  .nextTitle {
    font-size: 24px;
    line-height: 30px; }
  .nextStepsTitle {
    font-size: 22px;
    line-height: 28px; }
  .rewardsThanksTitle {
    line-height: 30px; }
  .thanksExclusiveCard {
    border: 3px solid #F4F6F7;
    box-shadow: 0 9px 12px rgba(41, 41, 41, 0.25);
    border-radius: 8px;
    .card-content {
      padding: 0.5rem 0.25rem !important; } }
  .exclusiveText {
    font-size: 16px; }
  .rewardsThanksCardButton {
    border: 1px solid #414141;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    font-size: 14px;
    line-height: 129%;
    letter-spacing: 0.115em; }
  .forYouNFamText {
    font-size: 15px;
    line-height: 108%; }
  .shareRewardText {
    font-size: 14px;
    line-height: 18px; } }

@media screen and (max-width: 768px) {
  .thanksTitle {
    font-size: 24px;
    line-height: 30px; }
  .nextTitle {
    font-size: 20px;
    text-align: left !important;
    line-height: 24px;
    margin-bottom: 0.5rem !important; }
  .nextStepsTitle {
    font-size: 16px;
    line-height: 20px; }
  .rewardsThanksTitle {
    font-size: 18px !important;
    text-align: left;
    margin-bottom: 0.35rem !important;
    line-height: 30px; }
  .thanksExclusiveCard {
    border: 3px solid #F4F6F7;
    box-shadow: 0 9px 12px rgba(41, 41, 41, 0.25);
    border-radius: 8px;
    .card-content {
      padding: 0.5rem 0.25rem !important; } }
  .exclusiveText {
    font-size: 12px !important; }
  .rewardsThanksCardButton {
    border: 1px solid #414141;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    font-size: 12px;
    line-height: 129%;
    letter-spacing: 0.115em; }
  .forYouText {
    font-size: 12px;
    word-break: normal; }
  .forYouNFamText {
    font-size: 11px;
    line-height: 14px; }
  .shareRewardText {
    font-size: 12px;
    line-height: 14px; }
  .turnUsernameText {
    font-size: 12px !important;
    line-height: 20px; }
  .pvtUsernameText {
    font-size: 12px !important; } }
